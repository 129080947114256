<template>

  <div id="page-user-list">

    <div class="vx-card p-6">

      <!-- Header -->
      <div class="flex justify-between flex-wrap items-center">

        <!-- Search Form -->
        <!-- <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQueryIn" @input="updateSearchQueryIn" placeholder="Search..." /> -->

        <vs-button color="primary" icon-pack="feather" icon="icon-plus" class="mr-4" @click="addItem">Add Item</vs-button>

      </div>
      
      <div class="vx-row mb-base my-4">
      <!-- CUSTOMERS CHART -->
      <div class="vx-col w-full mt-4 lg:w-1/2">
        <vx-card style="height: 100%;">
          <div class="flex justify-between items-center mb-3">
            <h4>Equipment In</h4>
            <vs-input class="sm:ml-4 ml-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQueryIn" @input="updateSearchQueryIn" placeholder="Search..." />
            
          </div>
          <ag-grid-vue
          ref="agGridTable"
        :components="components"
        :gridOptions="gridOptionsIn"
        class="ag-theme-material w-full ag-grid-table"
        :columnDefs="columnDefsIn"
        :defaultColDef="defaultColDef"
        :rowData="equipmentIns"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>
      <!-- <div class="vx-row"> -->
        <!-- <div class="vx-col sm:w-1/2 w-full"> -->
          <!-- ITEMS PER PAGE -->
          <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ equipmentOuts.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : equipmentOuts.length }} of {{ equipmentOuts.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button>
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div> -->
        <!-- <div class="vx-col sm:w-1/2 w-full"> -->
          <!-- PAGINATION -->
          <!-- <vs-pagination
            :total="totalPages"
            :max="7"
            v-model="currentPage" />
        </div>
      </div> -->
        </vx-card>
      </div>

      <div class="vx-col w-full mt-4 lg:w-1/2">
        <vx-card style="height: 100%;">
          <div class="flex justify-between items-center mb-3">
            <h4>Equipment Out</h4>
            <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQueryOut" @input="updateSearchQueryOut" placeholder="Search..." />
            
          </div>

          <ag-grid-vue
          ref="agGridTable"
        :components="components"
        :gridOptions="gridOptionsOut"
        class="ag-theme-material w-full ag-grid-table"
        :columnDefs="columnDefsOut"
        :defaultColDef="defaultColDef"
        :rowData="equipmentOuts"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>
        </vx-card>
      </div>
    </div>

      <!-- AgGrid Table -->
      
    <!-- <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-full my-4 ag-grid-table"
        :columnDefs="columnDefsOut"
        :defaultColDef="defaultColDef"
        :rowData="equipmentOuts"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue> -->

      <!-- Pagination -->
      <!-- <div class="vx-row"> -->
        <!-- <div class="vx-col sm:w-1/2 w-full"> -->
          <!-- ITEMS PER PAGE -->
          <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ equipmentOuts.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : equipmentOuts.length }} of {{ equipmentOuts.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div> -->
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <!-- <vs-dropdown-menu> -->

              <!-- <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div> -->
        <!-- <div class="vx-col sm:w-1/2 w-full"> -->
          <!-- PAGINATION -->
          <!-- <vs-pagination
            :total="totalPages"
            :max="7"
            v-model="currentPage" />
        </div>
      </div> -->

    </div>

    <!--  Popup Brand  -->
    <vs-popup classContent="popup-example" :title="`${mode} item`" :active.sync="popup">

      <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
          <small class="mb-1">{{ $i18n.t('Customer') }}</small>
          <v-select class="mb-3" v-model="form.customer" :clearable="false" :options="customers"></v-select>
        </div>
      </div>

      <div class="vx-row mb-3">
        <div class="vx-col md:w-1/1 w-full">
          <small class="mb-1">{{ $i18n.t('Date') }}</small>
          <vs-input type="datetime-local" @change="formatDate" v-model="form.date" class="w-full" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
          <vs-input class="w-full mb-3" :label="$i18n.t('Unit Name')" v-model="form.unit" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
          <small class="mb-1">{{ $i18n.t('Brand Name') }}</small>
          <v-select class="mb-3" v-model="form.brand" :clearable="false" :options="brands"></v-select>
        </div>
      </div>

      <div class="vx-row mt-3">
            <div class="vx-col md:w-1/1 w-1/2">
              <label>Picture</label>
              <vs-upload v-on:change="handleFileUpload" :multiple="false" limit="1" accept="image/jpeg, image/png, image/jpg" automatic />
              <div class="flex gap-3">
                <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" class="mt-3" @click="storeItem">
                  {{ $i18n.t('Save') }}
                </vs-button>
                <vs-button color="danger" type="filled" class="mt-3" @click="() => popup = false">
                  {{ $i18n.t('Batal') }}
                </vs-button>
              </div>
            </div>
            <div class="vx-col md:w-1/1 w-1/2">
              <div class="vx-row my-3 w-full">
                <label for="">Serial Number <small>(optional)</small></label>
                <vs-input class="w-full" v-model="form.serial_number" placeholder="Filled this or empty only" />  
              </div>
              <div class="vx-row my-3 w-full">
                <label for="">Kelengkapan </label>
                <vs-input class="w-full" v-model="form.completeness" placeholder="Filled this or empty only" />  
              </div>
              <div class="vx-row my-3 w-full">
                <small class="mb-1">Note</small>            
                <vs-textarea class="w-full" rows="6" v-model="form.note" />
              </div>
            </div>
          </div>
      
      <div>
       
      </div>

    </vs-popup>


  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import axios from '@/axios';

// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererActionsOut from './cell-renderer/CellRendererActionsOut.vue';
import CellRendererFile from './cell-renderer/CellRendererFile.vue'
import moment from 'moment'

import storage from '@/storage'


export default {
  components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererFile,
    CellRendererActionsOut
  },
  data () {
    return {

      searchQueryIn: '',
      searchQueryOut: '',
      year: '2020',
      yearOptions: ['2015', '2016', '2017', '2018', '2019', '2020'].reverse(),
      brands: [],
      show: false,
      showMode: '',

      form: {},
      popup: false,
      mode: 'add',
      itemSelected: false,
      customers: [],
      item: {
        equipment: {
          picture: null
        }
      },

      // AgGrid
      gridApiIn: null,
      gridOptionsIn: {},
      equipmentIns: [],
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefsIn: [
        {
          headerName: this.$i18n.t('Equipment'),
          field: 'equip_name',
          filter: true,
          headerClass: 'text-center',
          width: 215,
          cellClass: 'text-center'
        },
        {
          headerName: this.$i18n.t('Date'),
          field: 'equip_entry.date',
          headerClass: 'text-center',
          width: 130,
          cellClass: 'text-center',
          filter: true,
        },
        {
          headerName: 'Action',
          cellRendererFramework: 'CellRendererActions',
          headerClass: 'text-center',
          cellClass: 'text-center',
          width: 130,
          cellRendererParams: {
            showItem: this.showItem.bind(this),
            deleteItem: this.deleteItem.bind(this),
            itemOut: this.itemOut.bind(this),
          },
        }
      ],
      gridApiOut: null,
      gridOptionsOut: {},
      equipmentOuts: [],
      columnDefsOut: [
      {
          headerName: this.$i18n.t('Equipment'),
          field: 'equip_name',
          filter: true,
          headerClass: 'text-center',
          width: 215,
          cellClass: 'text-center'
        },
        {
          headerName: this.$i18n.t('Date'),
          field: 'equip_exit.date',
          headerClass: 'text-center',
          width: 150,
          cellClass: 'text-center',
          filter: true,
        },
        {
          headerName: 'Action',
          cellRendererFramework: 'CellRendererActionsOut',
          headerClass: 'text-center',
          cellClass: 'text-center',
          width: 130,
          cellRendererParams: {
            showItem: this.showItem.bind(this),
            deleteItem: this.deleteItem.bind(this)
          },
        }
      ],
    }
  },
  computed: {
    
    paginationPageSize () {
      if (this.gridApiIn) return this.gridApiIn.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApiIn) return this.gridApiIn.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApiIn) return this.gridApiIn.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApiIn.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    storagePath(){
      return `${storage.equipment}`
    },
    updateSearchQueryIn (val) {
      this.gridApiIn.setQuickFilter(val)
    },
    updateSearchQueryOut (val) {
      this.gridApiOut.setQuickFilter(val)
    },
    handleFileUpload(name, files) {
        // Assuming only one file is uploaded
        if (files.length > 0) {
          this.form.picture = files[0];
        }
      },
    formatDate(){
      return moment(this.form.date).format('YYYY-MM-DD HH:mm');
    },
    customerData(){
        axios.get('/customer').then(
          resp => {
            this.customers = resp.data.map(data => {
              return {
                label: data.name,
                code: data.id
              }
            })
          }
        ).catch(
          err => {
            this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
          }
        )
    },

    // append form data
    appendFormData (obj) {
      let formData = new FormData();

      Object.keys(obj).forEach(key => {
        formData.append(key, obj[key]);
      });

      return formData;
    },
    getData () {
      let params = {

      };

      this.$store.dispatch("equipment/getEquipments", "in").then(
        resp => {
          this.equipmentIns = resp.data.values
        }
      ).catch(
        err => {
          this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
        }
      )
      
      this.$store.dispatch("equipment/getEquipments", "out").then(
        resp => {
          this.equipmentOuts = resp.data.values
        }
      ).catch(
        err => {
          this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
        }
      )

      // axios.get('/technical/equipment/equipment/details', {params}).then(resp => {
      //   this.equipmentIns = resp.data.filter((data) => data.date_out == null)
      //   this.equipmentOuts = resp.data.filter((data) => data.date_out != null)
      // }
      
      // ).catch(err => {
      //   this.$vs.notify({
      //     title:'Opps something error',
      //     text: err.data.message,
      //     color:'danger'
      //   })
      // })
    },
    addItem () {
      this.popup = true;
      this.mode = 'add';
      this.showMode = '';
    },
    itemOut(item){
      this.$store.dispatch('equipment/storeEquipment', {
        status: 'out',
        body: item.payload
      }).then(
        resp => {
          this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.getData();
        });
        }
      ).catch(
        err => {
          this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
        }
      )
    },
    editItem () {
      this.show = false;
      this.popup = true;
      this.mode = 'edit';
      // this.itemSelected = item;

      this.form = {
        customer: {
          label: this.item.customer
        },
        date: this.showMode == 'in' ? this.item.date_in : this.item.date_out,
        time: this.showMode == 'in' ? this.item.time_in : this.item.time_out,
        unit: this.item.equipment.equip_name,
        brand: {
          label: this.item.equipment.brand.brand_name,
          code: this.item.equipment.brand.id
        },
        serial_number: this.item.sn_asal,
        completeness: this.item.completeness,
        note: this.item.remarks,
        
      }
    },
    showItem(item, mode){
      this.show = true
      this.showMode = mode
      this.item = item
    },
    deleteItem (id) {
      this.$store.dispatch('equipment/deleteEquipment', id)
      .then(resp => {
        this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.getData();
        });
      }).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },
    storeItem () {
      if (this.mode == 'add') {

        let body = {
          pic_name: this.$store.state.AppActiveUser.employee.name,
          customer: this.form.customer.label,
          date: this.form.date,
          equip_name: this.form.unit,
          brand_id: this.form.brand.code,
          serial_number: this.form.serial_number,
          completeness: this.form.completeness,
          picture: this.form.picture,
          notes: this.form.note
        }

        body = this.appendFormData(body);

        this.$store.dispatch('equipment/storeEquipment', {
          status: 'in',
          body: body
        })
        .then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getData();
            this.mode = false;

          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }
      else if (this.mode == 'edit') {
        let body = {
          pic_name: this.$store.state.AppActiveUser.employee.name,
          customer: this.form.customer.label,
          date: this.form.date,
          time: this.form.time,
          equip_name: this.form.unit,
          brand_id: this.form.brand.code,
          serial_number: this.form.serial_number,
          completeness: this.form.completeness,
          note: this.form.note,
          type: this.showMode,
          _method: 'PUT',
        };

        body = this.appendFormData(body);

        axios.post('/technical/equipment/' + this.item.id, body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getData();
            this.mode = false;
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }

    }
  },
  mounted () {
    this.gridApiIn = this.gridOptionsIn.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }

    this.gridApiIn.sizeColumnsToFit()

    this.gridApiOut = this.gridOptionsOut.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }

    this.gridApiOut.sizeColumnsToFit()
  },
  watch: {
    year () {
      this.getData();
    },
    $route () {
      this.getData();
    },
    popup () {
      if (!this.popup) {
        this.form = {};
      }
    }
  },
  created () {
    this.getData();
    this.customerData();

    axios.get('/technical/equipment/brand').then(resp => {
      this.brands = resp.data.values.map((d) => {
        return {
          label: d.brand_name,
          code: d.id,
        }
      });
    }).catch(err => {
      this.$vs.notify({
        title:'Opps something error',
        text: err.data.message,
        color:'danger'
      })
    })

  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
// .vs-popup--content {
//   overflow: visible !important;

// }
.vs-popup--content {
  // overflow: visible;
  max-height: 600px; /* Adjust the max-height according to your needs */
  overflow-y: auto;
}
.picture {
  width: 200px;
  height: 250px;
}
</style>
