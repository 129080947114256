<template>
    <div :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
    <feather-icon
      icon="EyeIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="showRecord"
    />
    <feather-icon
      icon="Trash2Icon"
      svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer"
      @click="confirmDeleteRecord"
    />
    <feather-icon
      
      icon="LogOutIcon"
      svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
      @click="beforePush"
    />

    <vs-popup classContent="popup-example" :title="`confirm out item`" :active.sync="popup">

 
      <div class="justify-center" >
          <div class="vx-row mt-2">
            <div class="vx-col md:w-1/1 w-full flex items-center gap-4">
              <label for="">Date: {{ !switchDate ? form.date : '' }} </label>
              <vs-input type="datetime-local" @change="formatDate" v-model="form.date" class="my-2" v-if="switchDate == true" />
            </div>  
          </div>
          <div class="flex gap-3">
            <label for="">Want to custom date?</label>
            <vs-switch color="dark" v-model="switchDate" />
          </div>
          
          <div class="vx-row my-2">
            <div class="vx-col md:w-1/1 w-1/2">
              <label>Picture</label>
              <vs-upload v-on:change="handleFileUpload" :multiple="false" limit="1" accept="image/jpeg, image/png, image/jpg" automatic />
              <div class="flex gap-3">
                <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" class="mt-3" @click="storeItem">
                  {{ $i18n.t('Save') }}
                </vs-button>
                <vs-button color="danger" type="filled" class="mt-3" @click="() => popup = false">
                  {{ $i18n.t('Batal') }}
                </vs-button>
                </div>
            </div>
            <div class="vx-col md:w-1/1 w-1/2">
              <div class="vx-row my-2 w-full">
                <label for="">Serial Number <small>(optional)</small></label>
                <vs-input class="w-full" v-model="form.serial_number" placeholder="Filled this or empty only" />  
              </div>
              <div class="vx-row my-2 w-full">
                <label for="">Kelengkapan</label>
                <vs-input class="w-full" v-model="form.completeness" placeholder="Filled this or empty only" />  
              </div>
              <div class="vx-row my-2 w-full">
                <small class="mb-1">Note</small>            
                <vs-textarea class="w-full" rows="6" v-model="form.note" />
              </div>
            </div>
          </div>
          
        </div>

        <!-- sparepart option -->
<!-- 
<div class="vx-row px-5 items-center gap-3">
  <label for="">Sparepart Out?</label>
  <vs-switch color="dark" v-model="switchSpare" />
  <small>Optional</small>
</div> -->

<div v-if="switchSpare == true">
  <div class="vx-row my-3">
    <div class="vx-col md:w-1/1 w-full" v-for="(spareId, index) in form.spareOutId" :key="spareId.id">
      <div class="flex gap-3 my-2">
        <small class="mb-1">{{ $i18n.t('Sparepart ')+(index+1) }}</small>
        <feather-icon icon="Trash2Icon" class="text-danger cursor-pointer h-5 w-5" @click="deleteSpare(index)"></feather-icon>
      </div>
      <div class="flex items-center gap-3">
        <v-select class="mb-3 w-1/2" v-model="form.spareOutId[index]" :clearable="false" :options="spareparts"></v-select>
        <v-select class="mb-3 w-1/2" v-model="form.spareOutId[index]" :clearable="false" :options="spareparts"></v-select>
      </div>
    </div>
  </div>
  <vs-button color="primary" icon="add" size="small" class="w-full" @click="addSpare">Add Sparepart</vs-button>
</div>
  

  <!-- <vs-row class="gap-2">
    save button
  </vs-row> -->

</vs-popup>

  </div>
</template>

<script>

import moment from 'moment';

export default {
  name: 'CellRendererActions',
  data() {
    return {
      popup: false,
      switchSpare: false,
      switchDate: false,
      form: {
        date: "",
        picture: null,
        serial_number: null,
        note: null
      },
      spareparts: [
        {
          label: "nothing",
          id: 0
        },
        {
          label: "Handphone",
          id: 1
        },
        {
          label: "Clu",
          id: 2
        },
        {
          label: "Hardisk",
          id: 3
        },
        {
          label: "Interface",
          id: 4
        },
        {
          label: "Power Supply",
          id: 5
        },
        {
          label: "Simboard",
          id: 6
        },
       
      ],
      form: {
        spareOutId: []
      }
    }
  },
  mounted() {
    this.form.serial_number = this.params.data.equip_entry.serial_number

    
    this.updateTime();
      // Update time every second
      setInterval(this.updateTime, 1000);
  },
  computed: {
    encrypt () {
      let id = this.params.data.id;
      return this.$secure.encrypt(id);
    }
  },
  methods: {
    addSpare(){
      return this.form.spareOutId.push(this.spareparts[0])
    },
    deleteSpare(index){
      return this.form.spareOutId.splice(index, 1)
    },
    showData () {
      this.$router.push({name: 'quotation-show', params: { id: this.encrypt }});
    },
    showRecord () {
      return this.$router.push({name: 'equipment-show', params: { id: this.encrypt }});
      // this.params.showItem(this.params.data, 'in');
    },
    formatDate(){
      return moment(this.form.date).format('YYYY-MM-DD HH:mm');
    },
    handleFileUpload(name, files) {
      // Assuming only one file is uploaded
      if (files.length > 0) {
        this.form.picture = files[0];
      }
    },
    updateTime() {
        // Update the currentTime data property with the current time using Moment.js
        if (this.switchDate != true) {
          this.form.date = moment().format('YYYY-MM-DD HH:mm');
        }
      },
    beforePush(){
      this.popup = true
    },  
    storeItem(){
      let body = {
        date: this.form.date,
        serial_number: this.form.serial_number,
        pic_name: this.$store.state.AppActiveUser.employee.name,
        picture: this.form.picture,
        notes: this.form.note,
        completeness: this.form.completeness,
        equipment_id: this.params.data.id,
      }

      body = this.appendFormData(body)

      this.popup = false
      this.params.itemOut({
        payload: body
      })
    },
    appendFormData (obj) {
      let formData = new FormData();

      Object.keys(obj).forEach(key => {
        formData.append(key, obj[key]);
      });

      return formData;
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Do you want delete it ?`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      this.params.deleteItem(this.params.data.id);
    },
    showDeleteSuccess (resp) {
      this.$vs.notify({
        color: 'success',
        title: 'Delete success',
        text: resp.data.message
      })
    },
    showDeleteError (err) {
      this.$vs.notify({
        title:'Opps something error',
        text: err.data.message,
        color:'danger'
      })
    }
  }
}
</script>
